import { FC, lazy } from "react"
import { Navigate, Outlet, RouteObject } from "react-router-dom"

import { Layout } from 'src/components/layout/DefaultLayout/Layout'
import { Loader } from "src/components/layout/Loader"
import { AuthLayout } from "src/components/layout/AuthLayout/AuthLayout"
import { Routes } from "src/types/routes"

const Dashboard = lazy<FC>(() => import("./dashboard"))
const SignIn = lazy<FC>(() => import("./auth/sign-in"))
const SignUp = lazy<FC>(() => import("./auth/sign-up"))
const ForgotPassword = lazy<FC>(() => import("./auth/forgot-password"))
const Surveys = lazy<FC>(() => import("./surveys"))
const Account = lazy<FC>(() => import("./account"))
const General = lazy<FC>(() => import("./account/general"))
const Payments = lazy<FC>(() => import("./account/payments"))

import { CustomerSignIn, ClientSignIn } from "./auth/sign-in-type-wrap"

const authRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate to={Routes.Dashboard} replace />,
  },
  {
    Component: Layout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Dashboard,
        path: Routes.Dashboard,
        index: true,
      },
      {
        Component: Surveys,
        path: Routes.Surveys,
        index: true,
      },
      {
        Component: Account,
        path: Routes.Account,
        children: [
          {
            Component: General,
            path: Routes.General,
          },
          {
            Component: Payments,
            path: Routes.Payments,
          }
        ]
      },
      {
        Component: Outlet,
        path: "*",
      },
    ],
  },
]

const unauthRoutes: RouteObject[] = [
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
  {
    Component: AuthLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: ClientSignIn,
        path: "/",
        children: [
          {
            path: '',
            Component: SignIn
          }
        ]
      },
      {
        Component: CustomerSignIn,
        path: Routes.CustomerSignIn,
        children: [
          {
            path: '',
            Component: SignIn
          }
        ]
      },
      {
        Component: ClientSignIn,
        path: Routes.ClientSignIn,
        children: [
          {
            path: '',
            Component: SignIn
          }
        ]
      },
      {
        Component: CustomerSignIn,
        path: Routes.CustomerSignUp,
        children: [
          {
            path: '',
            Component: SignUp
          }
        ]
      },
      {
        Component: ClientSignIn,
        path: Routes.ClientSignUp,
        children: [
          {
            path: '',
            Component: SignUp
          }
        ]
      },
      // {
      //   Component: SignUp,
      //   path: Routes.CustomerSignUp,
      // },
      // {
      //   Component: SignUp,
      //   path: Routes.ClientSignUp,
      // },
      {
        Component: ForgotPassword,
        path: Routes.ForgotPassword,
      },
    ],
  }
]

const undefinedAuthorization: RouteObject[] = [
  {
    path: "*",
    element: <Outlet />,
  },
]

export const routes = (authorized: boolean | undefined): RouteObject[] => {
  if (authorized === undefined) {
    return undefinedAuthorization
  }
  return authorized ? authRoutes : unauthRoutes
}

export default routes
