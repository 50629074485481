import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Header } from "."
import './Layout.less'
import { useCustomBreakpoint } from "src/lib/hooks/useCustomBreakpoint"
import { MobileHeader } from "./MobileHeader"

const Layout: FC = () => {
  const { isMobile } = useCustomBreakpoint()
  const location = useLocation()

  const headerVisible = location.pathname.includes('account') && isMobile

  return (
    <BaseLayout className={'default-layout'}>
      {!headerVisible && <BaseLayout.Header className={'default-layout-header'}>
        {isMobile ? <MobileHeader /> : <Header />}
      </BaseLayout.Header>}
      <BaseLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
    </BaseLayout>
  )
}

export { Layout }
