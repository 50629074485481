// import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, memo, useContext, useEffect, type FC, type PropsWithChildren } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { version } from "../../../package.json"
// import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"

import { useAuthStore } from "src/store/auth"
import { checkAuth } from "src/shared/api"
import { logout } from "src/actions/auth"

type ContextProps = {
  app: { version: string }
}

const app: ContextProps["app"] = { version }

const Context = createContext({ app })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>
}

const useApp: () => ContextProps = () => useContext(Context)

// const router = createBrowserRouter(routes)

const App: FC = () => {
  const setStore = useAuthStore(store => store.set)
  const loggedIn = useAuthStore(store => store.loggedIn)
  const router = createBrowserRouter(routes(loggedIn))
  
  useEffect(() => {
    const type = localStorage.getItem("type")
    if (type) {
      setStore(store => {
        store.type = type
      })
    }
  }, [])

  const initialAuth = async () => {
    let token = localStorage.getItem("token")
    if (!token) {
      logout()//.then(() => window.location.href = "/")
      return
    }
    
    try {
      let response: any = await checkAuth()
      response = await response.json()

      if (!response.user) {
        logout()//.then(() => window.location.href = "/")
        return
      }
      
      if (response.user.type) {
        localStorage.setItem("type", response.user.type)
      }

      setStore(store => {
        store.loggedIn = !!response
        store.user = response.user
        store.type = response.user.type
      })
    } catch (error) {
      logout()//.then(() => window.location.href = "/")
      console.log("Error:", error)
    }
  }

  useEffect(() => {
    initialAuth()
  }, [])

  return (
    <ContextProvider app={app}>
      <ConfigProvider theme={theme}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </ContextProvider>
  )
}

export { useApp }

export default memo(App)
export {App}
