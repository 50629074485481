import { Image, Space, Typography } from "antd"
import { FC } from "react"

import logoImage from '/src/assets/images/logo.png'

type LogoProps = {
  showName?: boolean
  size?: number
}

const Logo: FC<LogoProps> = ({ showName, size }) => {
  const onClick = () => {
    window.location.replace('/')
  }

  return (
    <Space onClick={onClick} style={{ cursor: 'pointer' }} direction={"horizontal"} align={"center"}>
      <Image 
        height={size}
        width={size}
        preview={false}
        src={logoImage} 
        aria-label={'logo'} 
      />

      {showName &&<Typography.Title 
        style={{ margin: 0, color: '#1F2D47' }}
        level={3}
      >
        WildData
      </Typography.Title>}
    </Space>
  )
}

export { Logo }
