export enum Routes {
  Dashboard = "/dashboard",
  ClientDashboard = "/client-dashboard",
  CustomerSignIn = "/customer-login",
  ClientSignIn = "/client-login",
  ClientSignUp = "/client-register",
  CustomerSignUp = "/customer-register",
  ForgotPassword = "/forgot-password",
  Surveys = "/surveys",
  Account = "/account",
  General = "/account/general",
  Payments = "/account/payments",
}