import { Col, Image, Row, Typography } from "antd"
import { FC } from "react"
import { Logo } from "../../logo"
import './Header.less'
import mockPersonAvatar from '/src/assets/images/mock-person-avatar.webp'
import { Link } from "react-router-dom"
import { Routes } from "src/types/routes"

const Header: FC = () => {
  return (
    <Row align={"middle"} justify={"space-between"} className={'header'}>
      <Col>
        <Logo showName size={40} />
      </Col>

      <Col>
        <Link to={Routes.General} className={'header-link'}>
          <Typography.Text className={'body-1-medium'}>
            Default User
          </Typography.Text>

          <Image 
            height={40} 
            width={40} 
            preview={false}
            rootClassName={'header-avatar-wrapper'} 
            className={'header-avatar'}
            src={mockPersonAvatar} 
          />
        </Link>
      </Col>
    </Row>
  )
}

export { Header }
