
import { useAuthStore } from "src/store/auth"

export function logout(): Promise<void> {
  localStorage.removeItem("token")
  localStorage.removeItem("type")
  useAuthStore.getState().set(store => {
    store.user = null
    store.type = null
    store.loggedIn = false
  })

  return new Promise(res => {
    res()
  })
}
