import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"

const AuthLayout: FC = () => (
  <BaseLayout style={{ minHeight: "100vh" }}>
    <Suspense>
      <Outlet />
    </Suspense>
  </BaseLayout>
)

export { AuthLayout }
