import { Col, Image, Row } from "antd"
import { FC } from "react"
import './MobileHeader.less'
import mockPersonAvatar from '/src/assets/images/mock-person-avatar.webp'
import { Link } from "react-router-dom"
import { Routes } from "src/types/routes"

const MobileHeader: FC = () => {
  return (
    <Row align={"middle"} justify={"space-between"} className={'mobile-header'}>
      <Col>
        <Link to={Routes.General} className={'header-link'}>
          <Image 
            height={56} 
            width={56} 
            preview={false}
            rootClassName={'header-avatar-wrapper'} 
            className={'header-avatar'}
            src={mockPersonAvatar} 
          />
        </Link>
      </Col>
    </Row>
  )
}

export { MobileHeader }
