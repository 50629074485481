import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorActiveBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      colorBgHeader: "unset",
      colorBgBody: "unset",
    },
    Typography: {
      fontFamily: "Poppins",
      fontSizeHeading1: 32,
      fontSizeHeading2: 24,
      fontSizeHeading3: 18,
      fontSizeHeading4: 16,
    },
    Input: {
      controlHeight: 40,
      borderRadius: 8,
      colorPrimaryHover: '#061014',
    },
    Checkbox: {
      colorBorder: '#061014',
      colorPrimary: '#061014',
      colorPrimaryHover: '#061014',
    },
    Card: {
      borderRadius: 8,
      colorBgBase: '#FFFFFF',
      paddingSM: 16,
      paddingLG: 24,
      paddingXS: 16,
    },
    Tag: {
      colorSuccess: '#00964B',
      colorError: '#FB1523',
      colorWarning: '#B8AC07',
    },
    Pagination: {
      colorPrimaryHover: '#2A7E55',
      colorPrimaryTextHover: '#2A7E55',
      colorPrimary: '#061014',
      colorPrimaryActive: '#2A7E55',
    }
  },
}

export default theme
