import { create } from "zustand"
import { produce, Immutable, Draft } from "immer"

type User = {
  email: string
  role: string
  responseID?: string
}

type AuthState = {
  loggedIn: boolean | undefined
  user: User | null
  type: string | null //"customer" | "client" | null
}

type AuthActions = {
  set: (cb: (store: Draft<AuthState>) => void) => void
}

export type AuthStore = Immutable<AuthState & AuthActions>

export const useAuthStore = create<AuthStore>((setStore, getStore) => ({
  set: (cb) => setStore(produce<AuthStore>(cb)),
  
  loggedIn: undefined,
  user: null,
  type: null
}))
