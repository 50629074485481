import { useEffect } from "react"
import { Outlet } from "react-router"

import { useAuthStore } from "src/store/auth"

export const CustomerSignIn = () => {
  useEffect(() => {
    useAuthStore.getState().set(store => {
      store.type = "customer"
    })

    // return () => {
    //   useAuthStore.getState().set(store => {
    //     store.type = null
    //   })
    // }
  },[])
  return <Outlet />
}

export const ClientSignIn = () => {
  useEffect(() => {
    useAuthStore.getState().set(store => {
      store.type = "client"
    })

    // return () => {
    //   useAuthStore.getState().set(store => {
    //     store.type = null
    //   })
    // }
  },[])
  return <Outlet />
}
