import ky, { ResponsePromise, KyResponse } from "ky"

const API_URL = `${import.meta.env.WEBSITE_API_BASE_URL ?? ""}/api/`

const api = ky.create({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [
      async request => {
        const token = localStorage.getItem("token")!
        if (token) {
          request.headers.set("Authorization", `Bearer ${token}`)
        }
        request.headers.set("Content-Type", "application/json")
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401 || response.status === 500) {
          console.error(response.status)
        }
        return response
      },
    ],
  },
})

const AUTH_PATH = "auth"
const SIGN_IN_PATH = "auth/sign-in"
const SIGN_UP_PATH = "auth/sign-up"
const SURVEYS_PATH = "surveys"

export async function checkAuth() {
  return api.get(AUTH_PATH)
}

export async function login(email: string, password: string, type: string, responseID: string | null): Promise<KyResponse> {
  return api(SIGN_IN_PATH, {
    method: "POST",
    json: { email, password, type, responseID },
  })
}

export async function signup(body: any) {
  return api(SIGN_UP_PATH, {
    method: "POST",
    json: body
  })
}

export async function getSurveys(params: any = null) {
  return api.get(SURVEYS_PATH)
}
